<script>
  import Keypad from "../keypad.svelte";
  import Key from "../key.svelte";
  import Transactions from "./transactions.svelte";
  import { Card } from "../../cash_desk/api";

  import { onMount } from "svelte";

  export let translations = {};
  export let userPermissions = {};
  export let balance = 0;
  export let amount = "0";
  export let id;
  export let create;
  export let locked;

  $: balanceString = String(balance).replace(".", ",");

  const colors = {
    "5": "rgb(209, 250, 243)",
    "10": "rgb(250, 219, 209)",
    "20": "rgb(209, 236, 250)",
    "50": "rgb(250, 226, 209)"
  };

  let uiMessage = undefined;
  let uiMessageStatus = "success";

  let card = new Card(id);
  function shake() {
    document.querySelector(".keypad").style.animationPlayState = "running";
  }

  onMount(async function() {
    document
      .querySelector(".keypad")
      .addEventListener("animationiteration", function({ target }) {
        target.style.animationPlayState = "paused";
      });
  });

  function notify(good, message) {
    uiMessage = message;
    uiMessageStatus = good ? "success" : "danger";
    let box = document.querySelector(".uk-alert");
    box.style.animation = "none";
    box.offsetWidth; // Reflow
    box.style.animation = "";
  }

  function clear() {
    amount = "0";
  }

  function input({ detail: { value } }) {
    if (value == ",") {
      if (amount.match(",")) shake();
      else amount = amount + ",";
    } else if (amount.match(/^0*$/)) {
      amount = value;
    } else if (amount.match(/,\d\d/)) {
      shake();
    } else {
      amount = amount + value;
    }
  }

  function add({ detail: { value } }) {
    amount = String(
      parseFloat(amount.replace(",", ".")) + parseFloat(value)
    ).replace(".", ",");
  }

  // TODO: Check length of amount decimal digits
  function floatAmount() {
    return parseFloat(amount.replace(",", "."));
  }

  function update() {
    card = card;
  }

  function handleError(error) {
    let message;
    if (error.code_text) {
      message = error.code_text;
      if (error.errors) {
        message += "<br>" + error.errors.reduce((a, b) => a + "<br>" + b);
      }
    }
    notify(false, message);
  }
  function handleResponse(status) {
    if (status) {
      let giftcard = status.giftcard;
      let code_text = status.code_text;

      balance = giftcard.amount;
      notify(true, code_text);
      update();
      clear();
    }

    return status;
  }

  function pressActivate() {
    card
      .charge(floatAmount())
      .catch(handleError)
      .then(handleResponse)
      .then(status => {
        // status is only set if activatation was successful

        if (status) {
          create = false;
        }
      });
  }

  function pressCharge() {
    card
      .charge(floatAmount())
      .catch(handleError)
      .then(handleResponse);
  }

  function pressEncash() {
    card
      .encash(floatAmount())
      .catch(handleError)
      .then(handleResponse);
  }

  function pressCancel() {
    return card
      .cancel()
      .then(handleResponse)
      .catch(handleError)
  }

  function pressUnlock() {
    pressCancel().then(status => {
      // status is only set if unlock was successful

      if (status) {
        locked = false;
      }
    });
  }

  function pressLock() {
    return card
      .lock()
      .then(handleResponse)
      .then(e => { locked = true })
      .catch(handleError)
  }
</script>

<style>
  .flex {
    display: flex;
    justify-content: center;
    /* gap: 1em; */
    /* TODO: switch to `gap` once it becomes available */
    margin: -1em;
  }
  .flex > * {
    margin: 1em; /* ugly gap replacement hack */
    flex: 1 1 0;
  }
  .flex.reverse {
    flex-direction: row-reverse;
  }
  .flex.wrap {
    flex-wrap: wrap;
  }
  .flex.wrap > * {
    min-width: max-content;
  }

  .flex > .keypad {
    font-size: 1.4em;
    display: grid;
    grid-template-columns: repeat(4, 2.8em);
    grid-auto-rows: 2.8em;
    gap: 0.2em;
    place-content: center center;
    place-items: stretch stretch;
    grid-auto-flow: dense row;
    animation: shake 0.3s linear infinite paused;
    flex: 0 0 auto;
  }

  .grid-4 {
    display: grid;
    grid-auto-rows: 2em;
    grid-gap: 0.4em;
    grid-template-columns: repeat(4, 1fr);
    max-width: 40em;
  }
  .full-width {
    grid-column: 1 / -1;
  }
  input.camouflaged:not(:focus) {
    border: none;
    outline: none;
  }
  input.camouflaged:focus {
    border: none;
    outline: 1px solid #ccc;
  }
  #amount {
    display: contents;
  }
  #amount > * {
    font-size: inherit;
    text-align: right;
    grid-column: 1/-2;
  }
  #amount::after {
    grid-column: span 1;
    display: inline;
    content: "€";
    line-height: 1.8em;
  }
  .info {
    font-size: inherit;
    text-align: center;
    grid-column: 1/-1;
  }

  .fade {
    transition: opacity var(--fade-time, 0.5s);
  }
  .fade-out {
    opacity: 0;
  }
  .fade-in {
    opacity: 1;
    animation: blink 0.6s;
  }

  @keyframes shake {
    0% {
      transform: rotateZ(0deg);
    }
    25% {
      transform: rotateZ(3deg);
    }
    50% {
      transform: rotateZ(-3deg);
    }
    75% {
      transform: rotateZ(3deg);
    }
    100% {
      transform: rotateZ(0deg);
    }
  }

  @keyframes blink {
    0% {
      opacity: 0;
    }
    33% {
      opacity: 0.8;
    }
    66% {
      opacity: 0.4;
    }
    100% {
      opacity: 1;
    }
  }

  #container {
    margin: 1em;
    margin-bottom: 3em;
  }
</style>

<!-- vim: set filetype=html autowriteall : -->
<!-- Wrapper necessary for -1 margin hack until gap is a thing (see below) -->
<div
  class="fade {uiMessage ? 'fade-in' : 'fade-out'} uk-alert-{uiMessageStatus}
  uk-alert">
  {@html uiMessage}
</div>
<div id="container">
  <div class="flex wrap reverse">
    <div class="grid-4">
      {#if !create}
        <div class="info">{translations.balance}: {balanceString} €</div>
      {/if}
      <span id="amount">
        <input class="camouflaged" type="text" bind:value={amount} />
      </span>
      <div class="flex full-width">
        {#if create}
          {#if userPermissions.canActivate}
            <div
              on:click={pressActivate}
              class="uk-button uk-button-primary full-width">
              {translations.activate}
            </div>
          {/if}
        {:else if locked}
          {#if userPermissions.canCancel}
            <div
              on:click={pressUnlock}
              class="uk-button uk-button-primary full-width">
              {translations.unlock}
            </div>
          {/if}
        {:else}
          {#if userPermissions.canCreditCards}
            <div
              on:click={pressCharge}
              class="uk-button uk-button-primary full-width">
              {translations.charge}
            </div>
          {/if}
          {#if userPermissions.canEncash}
            <div
              on:click={pressEncash}
              class="uk-button uk-button-primary full-width">
              {translations.encash}
            </div>
          {/if}
          {#if userPermissions.canCancel}
            <div
              on:click={pressCancel}
              class="uk-button uk-button-primary full-width">
              {translations.cancel}
            </div>
          {/if}
          {#if userPermissions.canCancel}
            <div
              on:click={pressLock}
              class="uk-button uk-button-danger full-width">
              {translations.lock}
            </div>
          {/if}
        {/if}
      </div>
    </div>

    <div class="keypad">
      {#each [5, 10, 20, 50] as number}
        <Key color={colors[number]} on:click={add} text={number} type="extra" />
      {/each}
      <Keypad on:input={input} on:clear={clear} />
    </div>
  </div>
</div>

{#if !create && !locked}
  <Transactions {card} translations={translations.transactions} />
{/if}
