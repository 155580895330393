export class Card {
  prefix = 'cash_desk';
  number = undefined;

  constructor(cardNumber) {
    this.number = cardNumber;
  }

  uri() {
    return `/${this.prefix}/${this.number}`;
  }

  fetch(method = 'GET', suffix = '', body = undefined) {
    return fetch(this.uri() + suffix, {
      method: method,
      body: JSON.stringify(body),
      headers: { 'content-type': 'application/json' },
    })
      .then((response) => response.json())
      .then(function ({ status }) {
        if (status.code == 'OK') {
          return status;
        } else {
          return Promise.reject(status);
        }
      });
  }

  encash(amount) {
    return this.fetch('PUT', '', { amount: amount });
  }
  charge(amount) {
    return this.fetch('POST', '', { amount: amount });
  }
  cancel() {
    return this.fetch('PUT', '/undo', {});
  }
  lock() {
    return this.fetch('PUT', '/lock', {});
  }
  transactions() {
    return this.fetch('GET', '/transactions');
  }
}
