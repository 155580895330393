import '../stylesheets/application';

import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';

// Floating Action Button for mobile devices
import 'mfb/src/mfb';

UIkit.use(Icons);

// Initialize navbar
UIkit.navbar('#navbar');
