<script>
  import { createEventDispatcher } from "svelte";
  import Key from "./key.svelte";

  const dispatch = createEventDispatcher();

  let keys = [[1, 2, 3], [4, 5, 6], [7, 8, 9], [",", 0, "C"]];

  function pressed({ detail: { value } }) {
    if (value == "C") dispatch("clear");
    else dispatch("input", { value });
  }
</script>

<!-- vim: set filetype=html autowriteall : -->
{#each keys as row}
  {#each row as key}
    {#if key == 'C'}
      <Key on:click={pressed} text={key} type="danger" />
    {:else}
      <Key on:click={pressed} text={key} />
    {/if}
  {/each}
{/each}
