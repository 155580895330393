<script>
  export let translations = {};
  export let card;
  $: transactions = card.transactions().catch(response => {
    return Promise.reject(response.code_text);
  });
</script>

<style>
  #container {
    margin: auto;
    max-width: 50em;
    height: 20em;
    overflow: auto;
    mask-image: linear-gradient(to bottom, black 70%, transparent);
    -webkit-mask-image: linear-gradient(to bottom, black 70%, transparent);
    padding-bottom: 2em;
  }
</style>

<!-- vim: set filetype=html autowriteall : -->
<div id="container">
  {#await transactions}
    <div>Waiting...</div>
  {:then response}
    <table
      class="uk-table uk-table-divider uk-table-striped uk-table-hover
      uk-table-responsive">
      <caption>{translations.transactions}</caption>
      <thead>
        <tr>
          <th>{translations.timestamp}</th>
          <th>{translations.user}</th>
          <th>{translations.amount}</th>
          <th>{translations.comment}</th>
        </tr>
      </thead>
      {#each response.transactions as transaction}
        <tr>
          <td>{transaction.formatted_timestamp}</td>
          <td>{transaction.user.name}</td>
          <td
            class:uk-text-danger={transaction.amount < 0}
            class:uk-text-success={transaction.amount > 0}>
            {String(parseFloat(transaction.amount).toFixed(2)).replace('.', ',')}
            €
          </td>
          <td>{transaction.comment}</td>
        </tr>
      {/each}
    </table>
  {:catch error}
    <div>{error}</div>
  {/await}
</div>
