<script>
  import { createEventDispatcher } from "svelte";
  const dispatch = createEventDispatcher();

  export let text = "";
  export let type;
  export let color;

  function click(e) {
    dispatch("click", { value: "" + text });
  }
</script>

<style>
  span {
    font-size: inherit;
    text-align: center;
    line-height: 1.4em;
    padding: 0.5em;
  }
  .left {
    grid-column: 1/1;
  }
</style>

<!-- vim: set filetype=html autowriteall : -->
{#if type == 'danger'}
  <span on:click={click} class="uk-button uk-button-danger">{text}</span>
{:else if color}
  <span
    style="background-color: {color}"
    on:click={click}
    class="left uk-button uk-button-default">
    {text}
  </span>
{:else}
  <span on:click={click} class="uk-button uk-button-default">{text}</span>
{/if}
