import Rails from '@rails/ujs';
import * as ActiveStorage from '@rails/activestorage';
import 'channels';

// Image assets
const images = require.context('../images', true);
const imagePath = (name) => images(name, true);

// Svelte Components
import './components/index';

// Custom UI javascripts
import './ui';

Rails.start();
ActiveStorage.start();
